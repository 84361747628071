import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchGroups, approveGroup } from "repos/group";
import { formatDDDDHHMM, setGlobalLoading } from "utils/appHelper";
import { sortAsc, sortDesc } from "utils/genericHelper";
import Loading from "views/_shared/Loading";

const GroupList = () => {
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    fetchGroups().then((_data) => {
      setLoading(false);
      setData1(_data.filter((d) => !d.isActive));
      setData2(_data.filter((d) => d.isActive));
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleApprove = async (e) => {
    if (confirm("Grubu onaylamak istediginize emin misiniz?")) {
      setLoading(true);
      const id = e.target.dataset.id;
      const group = data1.find((d) => d.id === id);

      const response = await approveGroup(id);
      if (response) {
        alert("Grup onaylandı");
        setData1(data1.filter((d) => d.id !== id));
        setData2([group, ...data2]);
      } else {
        alert("Grup onaylanmadı!");
      }
      setLoading(false);
    }
  };

  const handleSort = (index) => {
    if (data2.length < 2) return;

    let tmp = [...data2];
    const fields = ["title", "creatorName", "createDate"];

    const field = fields[index];

    //desc
    if (data2.at(0)[field] > data2.at(-1)[field]) {
      tmp.sort((a, b) => sortAsc(a, b, field));
    }
    //asc
    else {
      tmp.sort((a, b) => sortDesc(a, b, field));
    }
    setData2(tmp);
  };

  return (
    <div id="group-list-mobile">
      {!loading && (
        <>
          <button onClick={fetchData}>↻ YENILE</button>
          <br />
          <br />
        </>
      )}

      <h3>ONAY BEKLEYEN</h3>
      {loading ? (
        <Loading />
      ) : (
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Üye adı</th>
              <th>Grup adı</th>
              <th>Tarih</th>
            </tr>
          </thead>
          <tbody>
            {data1.map((g, i) => {
              return (
                <tr key={i}>
                  <td>
                    <button
                      data-id={g.id}
                      onClick={handleApprove}
                      style={{ padding: 10 }}
                    >
                      ▶
                    </button>
                  </td>
                  <td>{g.creatorName || "-"}</td>
                  <td>{g.title}</td>
                  <td>{formatDDDDHHMM(g.createDate)}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}>
                {data1.length === 0 ? "No records found" : ""}
              </td>
            </tr>
          </tfoot>
        </table>
      )}

      <h3>ONAYLANMIŞ</h3>
      <table>
        <thead>
          <tr>
            <th>
              <button onClick={() => handleSort(0)}>Grup adı</button>
            </th>
            <th>
              <button onClick={() => handleSort(1)}>Oluşturan</button>
            </th>
            <th>
              <button onClick={() => handleSort(2)}>Tarih</button>
            </th>
          </tr>
        </thead>
        <tbody>
          {data2.map((g, i) => {
            return (
              <tr key={i}>
                <td>{g.title}</td>
                <td>{g.creatorName || "-"}</td>
                <td>{formatDDDDHHMM(g.createDate)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default GroupList;
